import { useDisclosure } from '@common/ui-components';
import { noop } from '@utils';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

type SelectHubAndDateToCreateTripContextProps = {
  drawer: {
    isDrawerOpen: boolean;
    onDrawerOpen: () => void;
    onDrawerClose: () => void;
  };
  data: {
    selectedHubId: string;
    selectedDate: string;
  };
  actions: {
    setSelectedHubId: Dispatch<SetStateAction<string>>;
    setSelectedDate: Dispatch<SetStateAction<string>>;
  };
};

export const SelectHubAndDateToCreateTripContext =
  createContext<SelectHubAndDateToCreateTripContextProps>({
    drawer: {
      isDrawerOpen: false,
      onDrawerOpen: noop,
      onDrawerClose: noop,
    },
    data: {
      selectedHubId: '',
      selectedDate: '',
    },
    actions: {
      setSelectedHubId: noop,
      setSelectedDate: noop,
    },
  });

export const SelectHubAndDateToCreateTripProvider = ({
  children,
}: PropsWithChildren) => {
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const [selectedHubId, setSelectedHubId] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const contextValue = useMemo(
    () => ({
      drawer: {
        isDrawerOpen,
        onDrawerOpen,
        onDrawerClose,
      },
      data: {
        selectedHubId,
        selectedDate,
      },
      actions: {
        setSelectedHubId,
        setSelectedDate,
      },
    }),
    [
      isDrawerOpen,
      onDrawerOpen,
      onDrawerClose,

      selectedHubId,
      selectedDate,

      setSelectedHubId,
      setSelectedDate,
    ]
  );

  return (
    <SelectHubAndDateToCreateTripContext.Provider value={contextValue}>
      {children}
    </SelectHubAndDateToCreateTripContext.Provider>
  );
};

export const useSelectHubAndDateToCreateTrip = () =>
  useContext(SelectHubAndDateToCreateTripContext);

import { TripStatus } from '@types';

export const ALL_TRIPS_STATUSES: TripStatus[] = [
  TripStatus.Pending,
  TripStatus.Started,
  TripStatus.Completed,
  TripStatus.Canceled,
];

export const ALL_TRIPS_TAB = 'all';

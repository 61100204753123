import { gql } from '@common/bff';

export const GET_TRIP = gql`
  query TripModule_GetTrip($id: String!) {
    trip(id: $id) {
      id
      mode
      status
      originType
      plannedTo
      points {
        hubs {
          id
          alias
        }
      }
      rider {
        id
        fullName
        isExternal
      }
      deliveries {
        id
        status
        assortmentType
        etaMin
        etaMax
        hub {
          id
          alias
        }
        order {
          id
        }
        customer {
          address {
            formatted
          }
        }
      }
    }
  }
`;

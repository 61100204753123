import { gql } from '@common/bff';

export const GET_EXTERNAL_RIDER_JOURNEYS_FOR_TRIP = gql`
  query JourneyModule_GetExternalRiderJourneysForTrip(
    $hubId: String!
    $tripId: String
  ) {
    getExternalRiderJourneysForTrip(hubId: $hubId, tripId: $tripId) {
      rider {
        id
        fullName
      }
    }
  }
`;

import { useIntl } from '@common/i18n';

import { toastsIntlIds } from '../intl';
import { useBaseToast } from '../use-base-toast';

export function useOnLoadingToast() {
  const { formatMessage } = useIntl();

  return useBaseToast({
    title: formatMessage({ id: toastsIntlIds.onLoadingToast.title }),
    description: formatMessage({
      id: toastsIntlIds.onLoadingToast.description,
    }),
    status: 'info',
  });
}

import { useIntl } from '@common/i18n';
import { Button } from '@common/ui-components';
import { ReactElement } from 'react';

import { tripInfosIntlIds } from '../intl/trip-infos.ids';
import { TripActionButtonProps } from './trip-action-button-types';

export default function TripActionButton({
  isDisabled,
  variant,
  type,
  onClick,
}: Readonly<TripActionButtonProps>): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <Button
      isDisabled={isDisabled ?? false}
      variant={variant}
      onClick={onClick}
    >
      {formatMessage(
        {
          id: tripInfosIntlIds.tripActionButton.label,
        },
        { type: type }
      )}
    </Button>
  );
}

import {
  Flex,
  TabPanel,
  TabPanels,
  Tabs as UITabs,
} from '@common/ui-components';
import { ReactElement } from 'react';

import { StyledTabList } from './content-layout.styles';
import { ContentLayoutProps } from './content-layout.types';
import { TabsHeader } from './tabs-header';

export default function ContentLayout({
  customFilters,
  selectedTabIndex,
  items = [],
  hasTabs = true,
  onStatusChange,
}: Readonly<ContentLayoutProps>): ReactElement {
  return (
    <UITabs
      display="flex"
      defaultIndex={selectedTabIndex}
      flex={1}
      flexDir="column"
      onChange={onStatusChange}
      w="100%"
      isLazy
    >
      {hasTabs ? (
        <Flex
          wrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
        >
          <StyledTabList>
            <TabsHeader items={items} />
          </StyledTabList>

          {customFilters}
        </Flex>
      ) : (
        customFilters
      )}

      <TabPanels display="flex" flex={1}>
        {items.map((item) => (
          <TabPanel
            display="flex"
            flex={1}
            key={item.label}
            pb={0}
            pt={4}
            px={0}
            w="inherit"
          >
            {item.content}
          </TabPanel>
        ))}
      </TabPanels>
    </UITabs>
  );
}

import { useMutation } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Mutation } from '@types';

import { EDIT_TRIP } from './update-trip-points.mutation';
import { UseUpdateTripPointsResponse } from './update-trip-points.types';

export function useUpdateTripPoints(): UseUpdateTripPointsResponse {
  const [handleUpdateTripPoints, { data, loading, error }] = useMutation<
    Pick<Mutation, 'updateTripPoints'>
  >(EDIT_TRIP, {
    onCompleted: (content) => {
      hermesLogger.info(
        '[UpdateTripPoints] Trip update points sucessfully processed',
        content
      );
    },

    onError: (error) => {
      hermesLogger.error(
        '[UpdateTripPoints] Error processing Trip update points',
        {
          timestamp: new Date(),
          error: {
            stack: error.stack,
            message: error.message,
            kind: error.name,
          },
        }
      );
    },
  });

  async function updateTripPoints(tripId: string, deliveryIds: string[]) {
    return await handleUpdateTripPoints({
      variables: { tripId, deliveryIds },
    });
  }

  return {
    actions: {
      updateTripPoints,
    },
    data: data?.updateTripPoints,
    loading,
    error,
  };
}

import {
  ModalCloseButton,
  ModalContent,
  ModalOpenButton,
  useModal,
} from '@common/core-components/modals/base-modal';
import { Value } from '@common/core-components/multi-select';
import { useIntl } from '@common/i18n';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelect as Select,
  Text,
} from '@common/ui-components';
import { useCreateOrEditTrip } from '@modules/trips/contexts';
import { useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

import { tripInfosIntlIds } from '../intl/trip-infos.ids';
import { ChangeOrAssignTripRiderModalProps } from './change-or-assign-trip-rider-modal-types';

export default function ChangeOrAssignTripRiderModal({
  trigger,
  title,
  hubId,
  tripId,
  journeys,
  isGetJourneysLoading,
  getJourneys,
  onCancel,
  onSubmit,
}: Readonly<ChangeOrAssignTripRiderModalProps>) {
  const { formatMessage } = useIntl();

  const { isOpen } = useModal();

  const {
    data: { selectedRiderId },
    actions: { setSelectedRiderId, setRider },
  } = useCreateOrEditTrip();

  useEffect(
    function getJourneysForTrip() {
      if (isOpen && hubId) {
        getJourneys(hubId, tripId);
      }
    },
    [isOpen, hubId, tripId]
  );

  const journeysToSelectMapper =
    journeys?.map((journey) => {
      return {
        value: journey.rider.id,
        label: journey.rider.fullName,
      };
    }) ?? [];

  const noRiderOption = {
    value: null,
    label: formatMessage({
      id: tripInfosIntlIds.changeOrAssignTripRiderModal.selectRider.option
        .noRider,
    }),
  };

  function handleSelectedRiderId(selected: Value) {
    setSelectedRiderId(selected.value);
  }

  function handleUpdateSelectedRider() {
    const journey = journeys?.find(
      ({ rider }) => selectedRiderId === rider?.id
    );

    if (journey?.rider) {
      setRider({
        id: journey.rider.id,
        fullName: journey.rider.fullName,
        isExternal: journey.rider.isExternal,
      });

      return;
    }

    setRider(null);
  }

  return (
    <>
      <ModalOpenButton>{trigger}</ModalOpenButton>
      <ModalContent>
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text>{title}</Text>

          <ModalCloseButton>
            <Button
              variant="ghost"
              color="black"
              p={0}
              _hover={{ bg: 'blackAlpha.100' }}
            >
              <IoCloseOutline
                size={24}
                title={formatMessage({
                  id: tripInfosIntlIds.changeOrAssignTripRiderModal.button
                    .cancel.label,
                })}
              />
            </Button>
          </ModalCloseButton>
        </ModalHeader>

        <ModalBody>
          <Select
            placeholder={formatMessage({
              id: tripInfosIntlIds.changeOrAssignTripRiderModal.selectRider
                .placeholder,
            })}
            onChange={handleSelectedRiderId}
            options={[noRiderOption, ...journeysToSelectMapper]}
            useBasicStyles
          />
        </ModalBody>

        <ModalFooter gap={3}>
          <ModalCloseButton>
            <Button colorScheme="gray" gap={3} onClick={onCancel}>
              {formatMessage({
                id: tripInfosIntlIds.changeOrAssignTripRiderModal.button.cancel
                  .label,
              })}
            </Button>
          </ModalCloseButton>

          <ModalCloseButton>
            <Button
              bg="blue.500"
              isLoading={isGetJourneysLoading}
              onClick={onSubmit ?? handleUpdateSelectedRider}
            >
              {!tripId
                ? formatMessage({
                    id: tripInfosIntlIds.changeOrAssignTripRiderModal.button
                      .assign.label,
                  })
                : formatMessage({
                    id: tripInfosIntlIds.changeOrAssignTripRiderModal.button
                      .sendOffer.label,
                  })}
            </Button>
          </ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

import { useIntl } from '@common/i18n';
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@common/ui-components';
import { FormEventHandler, ReactElement, useState } from 'react';
import { MdOutlineSearch } from 'react-icons/md';

import { deliverySearchInputIntlIds } from './intl';

export type DeliverySearchInputProps = {
  onSearch: (searchBy: string, value: string) => void;
  orderId?: string;
  riderId?: string;
};

export default function DeliverySearchInput({
  onSearch,
  orderId,
  riderId,
}: DeliverySearchInputProps): ReactElement {
  const { formatMessage } = useIntl();

  const defaultSearchBy = !!riderId ? 'riderId' : 'orderId';
  const defaultValue = riderId ?? orderId ?? '';

  const [searchBy, setSearchBy] = useState(defaultSearchBy);

  const handleSubmit: FormEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const { elements } = event.currentTarget as typeof event.currentTarget & {
      elements: {
        search: {
          value: string;
        };
      };
    };

    onSearch(searchBy, elements.search.value);
  };

  return (
    <Flex
      as="form"
      gap={2}
      onSubmit={handleSubmit}
      minW="fit-content"
      justifyContent="flex-end"
    >
      <InputGroup w="auto">
        {orderId && riderId ? (
          <InputLeftAddon
            py={0}
            px={1}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
          >
            <select
              style={{
                background: 'transparent',
                border: 'none',
              }}
              onChange={(event) => setSearchBy(event.target.value)}
              value={searchBy}
            >
              <option value="orderId">
                {formatMessage({
                  id: deliverySearchInputIntlIds.type.orderId,
                })}
              </option>
              <option value="riderId">
                {formatMessage({
                  id: deliverySearchInputIntlIds.type.riderId,
                })}
              </option>
            </select>
          </InputLeftAddon>
        ) : (
          <InputLeftAddon py={0} px={2}>
            <Text as="strong" color="gray.600">
              {formatMessage(
                {
                  id: deliverySearchInputIntlIds.type.searchBy,
                },
                { searchBy: defaultSearchBy }
              )}
            </Text>
          </InputLeftAddon>
        )}

        <Input
          aria-label={formatMessage({
            id: deliverySearchInputIntlIds.input.label,
          })}
          borderRadius={0}
          defaultValue={defaultValue}
          name="search"
          w="100%"
          type="search"
          placeholder={formatMessage({
            id: deliverySearchInputIntlIds.input.placeholder,
          })}
        />

        <InputRightAddon p={0} border={0}>
          <Button
            borderTopLeftRadius={0}
            borderBottomLeftRadius={0}
            type="submit"
            aria-label={formatMessage({
              id: deliverySearchInputIntlIds.submitButton.label,
            })}
          >
            <MdOutlineSearch size={24} />
          </Button>
        </InputRightAddon>
      </InputGroup>
    </Flex>
  );
}

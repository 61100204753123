import { useQuery } from '@common/bff';
import { useOnSuccessToast } from '@common/core-components';
import { useIntl } from '@common/i18n';
import { hermesLogger } from '@common/observability';
import { tripsIntlIds } from '@modules/trips/intl';
import { Query, Subscription } from '@types';
import { useEffect } from 'react';

import { GET_TRIP } from './get-trip.query';
import { UseGetTripProps, UseGetTripResponse } from './get-trip.types';
import { TRIP_UPDATED_SUBSCRIPTION } from './trip-updated.subscription';

export default function useGetTrip({
  id,
}: UseGetTripProps): UseGetTripResponse {
  const { formatMessage } = useIntl();

  const onTripUpdatedSuccessfully = useOnSuccessToast({
    title: formatMessage({
      id: tripsIntlIds.tripDetails.toasts.onTripUpdatedSuccess.title,
    }),
  });

  const { data, loading, error, subscribeToMore } = useQuery<
    Pick<Query, 'trip'>
  >(GET_TRIP, {
    variables: { id },

    skip: !id,

    onCompleted: (content) => {
      hermesLogger.info(`[GetTrip] Successfully retrieved Trip ${id}`, content);
    },

    onError: (error) => {
      hermesLogger.error(`[GetTrip] Error getting Trip ${id}`, {
        timestamp: new Date(),
        error: {
          stack: error.stack,
          message: error.message,
          kind: error.name,
        },
      });
    },
  });

  useEffect(() => {
    if (id) {
      const unsubscribe = subscribeToMore<Pick<Subscription, 'tripUpdated'>>({
        document: TRIP_UPDATED_SUBSCRIPTION,
        variables: { id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          onTripUpdatedSuccessfully();

          return {
            ...prev,
            trip: {
              ...subscriptionData.data.tripUpdated,
            },
          };
        },
      });

      return () => unsubscribe();
    }
  }, [subscribeToMore, id, onTripUpdatedSuccessfully]);

  return {
    data: data?.trip,
    loading,
    error,
  };
}

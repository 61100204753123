import { Flex, Text } from '@common/ui-components';
import { TripMode } from '@types';
import { ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { tripInfosIntlIds } from '../intl/trip-infos.ids';
import { TripRiderAssignProps } from './trip-rider-assign-types';

export default function TripRiderAssign({
  trip,
  rider,
}: Readonly<TripRiderAssignProps>): ReactElement {
  const { formatMessage } = useIntl();

  const tripModeStatus = useMemo(() => {
    if (trip?.rider || rider) {
      return {
        color: 'gray.800',
        children: formatMessage({
          id: tripInfosIntlIds.tripDetails.assignmentMode.status.assigned,
        }),
      };
    }

    return {
      manual: {
        color: 'red.500',
        children: formatMessage({
          id: tripInfosIntlIds.tripDetails.assignmentMode.status
            .noRiderAssigned,
        }),
      },
      automatic: {
        color: 'yellow.700',
        children: formatMessage({
          id: tripInfosIntlIds.tripDetails.assignmentMode.status.findingRider,
        }),
      },
      external: {
        color: 'red.500',
        children: formatMessage({
          id: tripInfosIntlIds.tripDetails.assignmentMode.status
            .noRiderAssigned,
        }),
      },
    }[trip?.mode ?? TripMode.Manual];
  }, [formatMessage, trip?.mode, trip?.rider, rider]);

  return (
    <Flex direction="column">
      <Text fontWeight="600">
        {formatMessage(
          {
            id: tripInfosIntlIds.tripDetails.id.label,
          },
          { tripId: trip?.id ?? '-' }
        )}
      </Text>

      <Flex gap={2}>
        <Text as="span">
          {formatMessage(
            {
              id: tripInfosIntlIds.tripDetails.assignmentMode.label,
            },
            { tripMode: trip?.mode ?? TripMode.Manual }
          )}
        </Text>

        <Text {...tripModeStatus} />
      </Flex>
    </Flex>
  );
}

import { gql } from '@common/bff';

export const TRIP_CREATED_SUBSCRIPTION = gql`
  subscription TripModule_TripCreated {
    tripCreated {
      id
      mode
      status
      plannedTo
      points {
        hubs {
          id
          alias
        }
        deliveries {
          id
          status
        }
      }
      rider {
        id
        fullName
        isExternal
      }
    }
  }
`;

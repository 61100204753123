import { useIntl } from '@common/i18n';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@common/ui-components';
import { ReactElement } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

import {
  ModalCloseButton,
  ModalContent,
  ModalOpenButton,
  ModalProvider,
} from '../base-modal';
import { modalsIntlIds } from '../intl';

export type OnActionModalProps = {
  type: 'confirm' | 'cancel';
  onSubmit?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  trigger: ReactElement;
};

export default function OnActionModal({
  type,
  onSubmit,
  onCancel,
  onClose,
  trigger,
}: Readonly<OnActionModalProps>) {
  const { formatMessage } = useIntl();

  return (
    <ModalProvider>
      <ModalOpenButton>{trigger}</ModalOpenButton>

      <ModalContent>
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text>
            {formatMessage(
              { id: modalsIntlIds.onActionModal.header.title },
              { type }
            )}
          </Text>

          <ModalCloseButton>
            <Button
              variant="ghost"
              color="black"
              p={0}
              onClick={onClose}
              _hover={{ bg: 'blackAlpha.100' }}
            >
              <IoCloseOutline
                size={24}
                title={formatMessage({
                  id: modalsIntlIds.onActionModal.header.closeModalButton.label,
                })}
              />
            </Button>
          </ModalCloseButton>
        </ModalHeader>

        <ModalBody>
          <Text>
            {formatMessage(
              { id: modalsIntlIds.onActionModal.body.content },
              { type }
            )}
          </Text>
        </ModalBody>

        <ModalFooter gap={3}>
          <ModalCloseButton>
            <Button colorScheme="gray" onClick={onCancel}>
              {formatMessage(
                { id: modalsIntlIds.onActionModal.footer.onCancelButton.label },
                { type }
              )}
            </Button>
          </ModalCloseButton>

          <ModalCloseButton>
            <Button onClick={onSubmit}>
              {formatMessage(
                { id: modalsIntlIds.onActionModal.footer.onSubmitButton.label },
                { type }
              )}
            </Button>
          </ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </ModalProvider>
  );
}

import {
  Modal as ModalBase,
  ModalContent as ModalContentBase,
  ModalOverlay,
} from '@common/ui-components';
import { noop } from '@utils';
import {
  cloneElement,
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';

type ButtonProps = {
  children: ReactElement;
};

type AnyFunction = (...args: unknown[]) => void;

function callAll(...functions: AnyFunction[]) {
  return function (...args: unknown[]) {
    functions.forEach((fn) => {
      if (fn) {
        fn(...args);
      }
    });
  };
}

const ModalContext = createContext({
  isOpen: false,
  setIsOpen: noop,
});

export const useModal = () => useContext(ModalContext);

function ModalProvider({ children }: Readonly<PropsWithChildren>) {
  const [isOpen, setIsOpen] = useState(false);

  const memoizedValues = useMemo(
    () => ({ isOpen, setIsOpen }),
    [isOpen, setIsOpen]
  );

  return (
    <ModalContext.Provider value={memoizedValues}>
      {children}
    </ModalContext.Provider>
  );
}

function ModalCloseButton({ children }: ButtonProps) {
  const { setIsOpen } = useModal();

  return cloneElement(children, {
    onClick: callAll(() => setIsOpen(false), children.props.onClick),
  });
}

function ModalOpenButton({ children }: ButtonProps) {
  const { setIsOpen } = useModal();

  return cloneElement(children, {
    onClick: callAll(() => setIsOpen(true), children.props.onClick),
  });
}

function Modal({ children }: Readonly<PropsWithChildren>) {
  const { isOpen, setIsOpen } = useModal();

  return (
    <ModalBase isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />

      {children}
    </ModalBase>
  );
}

function ModalContent({ children }: Readonly<PropsWithChildren>) {
  return (
    <Modal>
      <ModalContentBase>{children}</ModalContentBase>
    </Modal>
  );
}

export { ModalCloseButton, ModalContent, ModalOpenButton, ModalProvider };

import { gql } from '@common/bff';

export const CREATE_TRIP = gql`
  mutation TripModule_CreateTrip(
    $deliveryIds: [String!]!
    $mode: TripMode!
    $originType: TripOriginType!
    $riderId: String
  ) {
    createTrip(
      deliveryIds: $deliveryIds
      mode: $mode
      originType: $originType
      riderId: $riderId
    )
  }
`;

import { gql } from '@common/bff';

export const EDIT_TRIP = gql`
  mutation TripModule_UpdateTripPoints(
    $tripId: String!
    $deliveryIds: [String!]!
  ) {
    updateTripPoints(tripId: $tripId, deliveryIds: $deliveryIds)
  }
`;

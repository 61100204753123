import { useMutation } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Mutation } from '@types';

import { CREATE_TRIP } from './create-trip.mutation';
import { UseCreateTripArgs, UseCreateTripResponse } from './create-trip.types';

export default function useCreateTrip(): UseCreateTripResponse {
  const [handleCreateTrip, { data, loading, error }] = useMutation<
    Pick<Mutation, 'createTrip'>
  >(CREATE_TRIP, {
    onCompleted: (content) => {
      hermesLogger.info(
        '[CreateTrip] Trip creation sucessfully processed',
        content
      );
    },

    onError: (error) => {
      hermesLogger.error('[CreateTrip] Error processing Trip creation', {
        timestamp: new Date(),
        error: {
          stack: error.stack,
          message: error.message,
          kind: error.name,
        },
      });
    },
  });

  async function createTrip({
    deliveryIds,
    mode,
    originType,
    riderId,
  }: UseCreateTripArgs) {
    return await handleCreateTrip({
      variables: { deliveryIds, mode, originType, riderId },
    });
  }

  return {
    actions: {
      createTrip,
    },
    data: data?.createTrip,
    loading,
    error,
  };
}

import { BffClientError } from '@common/bff';
import { useDisclosure } from '@common/ui-components';
import { Delivery, Rider, Trip } from '@types';
import { noop } from '@utils';
import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useGetTrip } from '../../use-cases';

type CreateOrEditTripContextProps = {
  data: {
    selectedRiderId: string;
    rider: Partial<Rider> | null;
    trip?: Trip | null;
    selectedDeliveries: Delivery[];
    addedDeliveriesToTrip: Delivery[];
    shouldSuspendTripUpdates: boolean;
  };
  actions: {
    setSelectedRiderId: Dispatch<SetStateAction<string>>;
    setRider: Dispatch<SetStateAction<Partial<Rider> | null>>;
    setSelectedDeliveries: Dispatch<SetStateAction<Delivery[]>>;
    setAddedDeliveriesToTrip: Dispatch<SetStateAction<Delivery[]>>;
    handleSuspendTripUpdates: () => void;
    onCloseTripAlreadyStartedModal: () => void;
  };
  isTripLoading: boolean;
  hasErrorOnTrip?: BffClientError;
};

export const CreateOrEditTripContext =
  createContext<CreateOrEditTripContextProps>({
    data: {
      selectedRiderId: '',
      rider: null,
      trip: null,
      selectedDeliveries: [],
      addedDeliveriesToTrip: [],
      shouldSuspendTripUpdates: false,
    },
    actions: {
      setSelectedRiderId: noop,
      setRider: noop,
      setSelectedDeliveries: noop,
      setAddedDeliveriesToTrip: noop,
      handleSuspendTripUpdates: noop,
      onCloseTripAlreadyStartedModal: noop,
    },
    isTripLoading: false,
    hasErrorOnTrip: undefined,
  });

export const CreateOrEditTripProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const tripId = router?.query.tripId as string;

  const {
    data: trip,
    loading,
    error,
  } = useGetTrip({
    id: tripId,
  });

  const [selectedRiderId, setSelectedRiderId] = useState('');

  const [rider, setRider] = useState<Partial<Rider> | null>(null);

  const [selectedDeliveries, setSelectedDeliveries] = useState<Delivery[]>([]);
  const [addedDeliveriesToTrip, setAddedDeliveriesToTrip] = useState<
    Delivery[]
  >([]);

  const contextValue = useMemo(
    () => ({
      data: {
        selectedRiderId,
        trip,
        rider,
        selectedDeliveries,
        addedDeliveriesToTrip,
        shouldSuspendTripUpdates: isOpen,
      },
      actions: {
        setSelectedRiderId,
        setRider,
        setSelectedDeliveries,
        setAddedDeliveriesToTrip,
        handleSuspendTripUpdates: onOpen,
        onCloseTripAlreadyStartedModal: onClose,
      },
      isTripLoading: loading,
      hasErrorOnTrip: error,
    }),
    [
      selectedRiderId,
      trip,
      rider,
      selectedDeliveries,
      addedDeliveriesToTrip,
      isOpen,

      setSelectedRiderId,
      setRider,
      setSelectedDeliveries,
      setAddedDeliveriesToTrip,
      onOpen,
      onClose,

      loading,
      error,
    ]
  );

  return (
    <CreateOrEditTripContext.Provider value={contextValue}>
      {children}
    </CreateOrEditTripContext.Provider>
  );
};

export const useCreateOrEditTrip = () => useContext(CreateOrEditTripContext);

import { useLazyQuery } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Query } from '@types';

import { GET_EXTERNAL_RIDER_JOURNEYS_FOR_TRIP } from './get-external-rider-journeys-for-trip.query';
import {
  UseGetExternalRiderJourneysForTripProps,
  UseGetExternalRiderJourneysForTripResponse,
} from './get-external-rider-journeys-for-trip.types';

export default function useGetExternalRiderJourneysForTrip({
  hubId,
  tripId,
}: UseGetExternalRiderJourneysForTripProps): UseGetExternalRiderJourneysForTripResponse {
  const [getJourneys, { data, loading, error }] = useLazyQuery<
    Pick<Query, 'getExternalRiderJourneysForTrip'>
  >(GET_EXTERNAL_RIDER_JOURNEYS_FOR_TRIP, {
    variables: { tripId, hubId },

    fetchPolicy: 'no-cache',

    onCompleted: (content) => {
      hermesLogger.info(
        '[GetExternalRiderJourneys] Successfully retrieved all Journeys for Trip',
        content
      );
    },

    onError: (error) => {
      hermesLogger.error(
        '[GetExternalRiderJourneys] Error getting Journeys for Trip',
        {
          timestamp: new Date(),
          error: {
            stack: error.stack,
            message: error.message,
            kind: error.name,
          },
        }
      );
    },
  });

  return {
    actions: {
      getJourneys: (hubId: string, tripId?: string) => {
        getJourneys({
          variables: { hubId, tripId },
        });
      },
    },
    data: data?.getExternalRiderJourneysForTrip,
    loading,
    error,
  };
}

import { useQuery } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Query, Subscription } from '@types';
import { useEffect } from 'react';

import { ALL_TRIPS_STATUSES } from './get-all-trips.constants';
import { GET_ALL_TRIPS } from './get-all-trips.query';
import {
  UseGetAllTripsProps,
  UseGetAllTripsResponse,
} from './get-all-trips.types';
import { TRIP_CREATED_SUBSCRIPTION } from './trip-created.subscription';

export default function useGetAllTrips({
  hubIds = [],
  statuses = ALL_TRIPS_STATUSES,
  originType,
  limit,
  offset,
  onTripCreated,
}: UseGetAllTripsProps): UseGetAllTripsResponse {
  const { data, loading, error, subscribeToMore, refetch } = useQuery<
    Pick<Query, 'tripsPaginated'>
  >(GET_ALL_TRIPS, {
    variables: { hubIds, statuses, originType, limit, offset },

    onCompleted: (content) => {
      hermesLogger.info(
        '[GetAllTrips] Successfully retrieved all Trips',
        content
      );
    },

    onError: (error) => {
      hermesLogger.error('[GetAllTrips] Error getting all Trips', {
        timestamp: new Date(),
        error: {
          stack: error.stack,
          message: error.message,
          kind: error.name,
        },
      });
    },
  });

  useEffect(() => {
    const unsubscribeTripCreated = subscribeToMore<
      Pick<Subscription, 'tripCreated'>
    >({
      document: TRIP_CREATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        refetch();

        onTripCreated?.();

        return {
          ...prev,
          tripsPaginated: {
            ...prev.tripsPaginated,
            trips: [
              ...prev.tripsPaginated.trips,
              subscriptionData.data.tripCreated,
            ],
          },
        };
      },
    });

    return () => unsubscribeTripCreated();
  }, [subscribeToMore, refetch, onTripCreated]);

  return {
    data: data?.tripsPaginated,
    loading,
    error,
  };
}

import { useIntl } from '@common/i18n';
import { Text } from '@common/ui-components';
import { ExternalDispatchStatus } from '@types';
import { ReactElement, useMemo } from 'react';

import { manualOrAutomaticRiderAssignmentIntlIds } from './intl';
import { ManualOrAutomaticRiderAssignmentProps } from './manual-or-automatic-rider-assignment.types';

export default function ManualOrAutomaticRiderAssignment({
  mode,
  rider,
  externalDispatch,
}: Readonly<ManualOrAutomaticRiderAssignmentProps>): ReactElement {
  const { formatMessage } = useIntl();

  const props = useMemo(() => {
    if (rider && !rider.isExternal) {
      return {
        color: 'blue.600',
        children: formatMessage(
          {
            id: manualOrAutomaticRiderAssignmentIntlIds.status.riderAssigned,
          },
          { rider_name: rider.fullName }
        ),
      };
    }

    if (rider && rider.isExternal) {
      return {
        children: formatMessage(
          {
            id: manualOrAutomaticRiderAssignmentIntlIds.status.riderAssigned,
          },
          { rider_name: rider.fullName }
        ),
      };
    }

    if (
      externalDispatch?.riderName &&
      externalDispatch?.status !== ExternalDispatchStatus.Canceled
    ) {
      return {
        color: 'blue.600',
        children: formatMessage(
          {
            id: manualOrAutomaticRiderAssignmentIntlIds.status.riderAssigned,
          },
          { rider_name: `Uber ${externalDispatch.riderName}` }
        ),
      };
    }

    return {
      manual: {
        color: 'red.500',
        children: formatMessage({
          id: manualOrAutomaticRiderAssignmentIntlIds.status.noRiderAssigned,
        }),
      },
      automatic: {
        color: 'yellow.600',
        children: formatMessage({
          id: manualOrAutomaticRiderAssignmentIntlIds.status.findingRider,
        }),
      },
      external: {
        color: 'red.500',
        children: formatMessage({
          id: manualOrAutomaticRiderAssignmentIntlIds.status.noRiderAssigned,
        }),
      },
    }[mode];
  }, [
    formatMessage,
    rider,
    mode,
    externalDispatch?.riderName,
    externalDispatch?.status,
  ]);

  return (
    <>
      {!rider?.isExternal && (
        <Text overflow="hidden" textOverflow="ellipsis">
          {formatMessage(
            {
              id: manualOrAutomaticRiderAssignmentIntlIds.mode
                .manualOrAutomatic,
            },
            {
              trip_mode: mode,
            }
          )}
        </Text>
      )}

      <Text
        fontWeight={500}
        overflow="hidden"
        textOverflow="ellipsis"
        {...props}
      />
    </>
  );
}

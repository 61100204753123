import { useIntl } from '@common/i18n';
import { Avatar, Flex, Text } from '@common/ui-components';
import { createHighlightTextWrapper } from '@utils';
import { ReactElement, ReactNode } from 'react';

import { tripInfosIntlIds } from '../intl/trip-infos.ids';
import { TripRiderInfosProps } from './trip-rider-infos-types';

export default function TripRiderInfos({
  rider,
  hub,
}: Readonly<TripRiderInfosProps>): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <Flex gap={6}>
      <Avatar
        title={formatMessage({
          id: tripInfosIntlIds.tripRiderInfos.avatar.title,
        })}
      />

      <Flex direction="column" gap={1}>
        <Flex gap={4}>
          <Text fontSize="md" fontWeight="600">
            {rider.fullName}
          </Text>
          <Text>ID: {rider?.id}</Text>
        </Flex>
        <Flex gap={4}>
          <Text color="gray.600" fontSize="sm">
            {formatMessage(
              {
                id: tripInfosIntlIds.tripRiderInfos.hub,
              },
              {
                hubAlias: hub?.alias,
                highlight: (chunks: ReactNode) =>
                  createHighlightTextWrapper(chunks, 'gray.600'),
              }
            )}
          </Text>

          {rider?.isExternal ? (
            <Text color="gray.600" fontSize="sm">
              {formatMessage(
                {
                  id: tripInfosIntlIds.tripRiderInfos.modal,
                },
                {
                  highlight: (chunks: ReactNode) =>
                    createHighlightTextWrapper(chunks, 'gray.600'),
                }
              )}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}

import { useMutation } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Mutation } from '@types';

import { UNDO_TRIP } from './undo-trip.mutation';
import { UseUndoTripResponse } from './undo-trip.types';

export default function useUndoTrip(): UseUndoTripResponse {
  const [handleUndoTrip, { data, loading, error }] = useMutation<
    Pick<Mutation, 'undoTrip'>
  >(UNDO_TRIP, {
    onCompleted: (content) => {
      hermesLogger.info('[UndoTrip] Trip undo sucessfully processed', content);
    },

    onError: (error) => {
      hermesLogger.error('[UndoTrip] Error processing Trip undo', {
        timestamp: new Date(),
        error: {
          stack: error.stack,
          message: error.message,
          kind: error.name,
        },
      });
    },
  });

  async function undoTrip(tripId: string) {
    return await handleUndoTrip({
      variables: { tripId },
    });
  }

  return {
    actions: {
      undoTrip,
    },
    data: data?.undoTrip,
    loading,
    error,
  };
}

import { useToast, UseToastOptions } from '@common/ui-components';

export type BaseToastProps = {
  title: string;
  description?: string;
  status?: UseToastOptions['status'];
};

export function useBaseToast({ title, description, status }: BaseToastProps) {
  return useToast({
    title,
    description,
    status,
    isClosable: true,
    variant: 'subtle',
    position: 'top-right',
  });
}

import { gql } from '@common/bff';
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET } from '@use-cases/utils';

export const GET_ALL_TRIPS = gql`
  query TripModule_GetAllTrips(
    $hubIds: [String!]
    $statuses: [TripStatus!]
    $originType: TripOriginType
    $offset: Int = ${DEFAULT_LIST_OFFSET}
    $limit: Int = ${DEFAULT_LIST_LIMIT}
  ) {
    tripsPaginated(hubIds: $hubIds, statuses: $statuses, originType: $originType, offset: $offset, limit: $limit) {
      limit
      offset
      totalCount
      trips {
        id
        mode
        status
        plannedTo
        points {
          hubs {
            id
            alias
          }
          deliveries {
            id
            status
          }
        }
        rider {
          id
          fullName
          isExternal
        }
      }
    }
  }
`;
